<template>
  <div class="site">
    <el-breadcrumb separator-class="el-icon-arrow-right">
      <el-breadcrumb-item :to="{ path: '/admin/index' }">数据概览</el-breadcrumb-item>
      <el-breadcrumb-item>基础设置</el-breadcrumb-item>
      <el-breadcrumb-item>短信设置</el-breadcrumb-item>
    </el-breadcrumb>
    <div class="page-content">
      <el-form ref="formRef" :model="form" label-width="120px">
        <el-form-item label="秘钥ID">
          <el-input v-model="form.keid"></el-input>
        </el-form-item>
        <el-form-item label="密钥SecretKey">
          <el-input v-model="form.secretkey"></el-input>
        </el-form-item>
        <el-form-item label="短信应用APPID">
          <el-input v-model="form.appid"></el-input>
        </el-form-item>
        <el-form-item label="短信签名">
          <el-input v-model="form.signature"></el-input>
        </el-form-item>
        <el-form-item label="登录模板ID">
          <el-input v-model="form.loginid"></el-input>
        </el-form-item>
        <el-form-item label="注册模板ID">
          <el-input v-model="form.registerid"></el-input>
        </el-form-item>
        <el-form-item label="找回密码模板ID">
          <el-input v-model="form.findpdid"></el-input>
        </el-form-item>
        <el-form-item label="普通短信模板ID">
          <el-input v-model="form.generalid"></el-input>
        </el-form-item>
        <el-form-item>
          <el-button type="primary" @click="onSubmit">立即创建</el-button>
          <el-button>取消</el-button>
        </el-form-item>
      </el-form>
    </div>
  </div>
</template>

<script>
export default {
  name: 'Sms',
  data () {
    return {
      form: {
        keid: '',
        secretkey: '',
        appid: '',
        signature: '',
        loginid: '',
        registerid: '',
        findpdid: '',
        generalid: ''
      }
    }
  },
  created () {
    this.getInfo()
  },
  methods: {
    // 获取信息
    async getInfo () {
      const { data: res } = await this.$http.get('sms')
      if (res.status === 200) {
        this.form.keid = res.data.keid
        this.form.secretkey = res.data.secretkey
        this.form.appid = res.data.appid
        this.form.signature = res.data.signature
        this.form.loginid = res.data.loginid
        this.form.registerid = res.data.registerid
        this.form.findpdid = res.data.findpdid
        this.form.generalid = res.data.generalid
      } else {
        this.$message.error(res.msg)
      }
    },
    // 提交表单
    async onSubmit () {
      const { data: res } = await this.$http.post('sms', this.form)
      if (res.status === 200) {
        this.$message.success(res.msg)
        this.$refs.formRef.resetFields()
      } else {
        this.$message.error(res.msg)
      }
    }
  }
}
</script>

<style scoped>

</style>
